import moment from 'moment'
import _ from 'lodash'
import store from 'setup/redux/Store'
import constants, {
  ATTENDANCE_STATUS,
  BASE_HOSTS,
  CERTIFICATE_TYPES,
  TENANT_CUSTOM_DOMAINS,
  DEFAULT_TENANT_VALUE,
  DIGITAL_REQUEST_OBJECT_TYPE,
  DIGITAL_REQUEST_STATUS,
  DIGITAL_REQUEST_TYPE,
  LIBRARY_STATUS,
  GRADE_STATUS,
  SECRET_KEY,
  USER_TYPES,
  USER_TYPES_NUMERIC,
  VENDOR_STATUS,
  base_url,
  USER_INACTIVE_REASON,
  CLASS_WISE_INSTANCE,
  SUBJECT_WISE_INSTANCE,
  CLASSES_STATUS,
  SUBJECTS_STATUS,
  GENDER,
  TENANT_CUSTOM_IMAGES,
  TENANT_CUSTOM_IFRAME_URL,
  CLASSES_SUBJECT_STATUS,
  REGISTRATION_STATUS,
  TEACHER_MARITAL_STATUS,
} from 'common/constants'
import {Option} from 'app/pages/school/settings/utils/settingsData'
import customMappings from 'common/customTitles/mappings.json'
import {getLang} from 'common/i18n/Metronici18n'
import enDictonary from 'common/i18n/messages/en.json'
import urDictonary from 'common/i18n/messages/ur.json'
import arDictonary from 'common/i18n/messages/en.json'
import axios from 'axios'
import {IntlShape} from 'react-intl'

export const getAppEnv = () => process.env.REACT_APP_ENV || 'local'

export const fieldsFilled = ({...stateObject}) => {
  let flag = Object.values(stateObject).every((x) => x !== null && x !== '')
  return flag
}

export function emailIsValid(email: string) {
  return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)
}

export const formatDate = (date: Date, format = 'YYYY-MM-DD') =>
  date ? moment(date, format).format('DD MMM YYYY') : ''

export const formatTime = (date: Date) => (date ? moment(date).format('hh:mm a') : '')

export const toTitleCase = (str: string) =>
  str
    ?.split('_')
    .join(' ')
    .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase())

export const getTenantNameFromCustomDomain = (customDomain: string): string | null => {
  const url = customDomain.startsWith('www.') ? customDomain.replace('www.', '') : customDomain
  for (const key in TENANT_CUSTOM_DOMAINS) {
    if (Object.hasOwnProperty.call(TENANT_CUSTOM_DOMAINS, key)) {
      const index = key as keyof typeof TENANT_CUSTOM_DOMAINS
      const customDomains = TENANT_CUSTOM_DOMAINS[index]
      if (customDomains.includes(url)) {
        return key
      }
    }
  }
  return null
}

export const getTenantName = (): string => {
  const reduxStore = store.getState()
  const userInfo = reduxStore.userReducer

  let tenantName = `${userInfo.tenant ? userInfo.tenant : DEFAULT_TENANT_VALUE}`

  const hostname = window.location.hostname
  if (!BASE_HOSTS.some((baseHost) => hostname.includes(baseHost))) {
    const tenantNameFromCustomDomain = getTenantNameFromCustomDomain(window.location.hostname)
    if (tenantNameFromCustomDomain) {
      tenantName = tenantNameFromCustomDomain
    }
  }

  return tenantName
}

export const getTenantDomain = (): string => {
  const env = getAppEnv()
  const tld = ['prod', 'dev'].includes(env) ? 'net' : 'test'
  return `${getTenantName()}.ilmversity.${tld}`
}

export const getTenantCustomDomain = (): string => {
  const tenantName = getTenantName()
  const customIframeUrl = TENANT_CUSTOM_IFRAME_URL[tenantName]

  const env = getAppEnv()
  if (!customIframeUrl || env === 'local') return getTenantDomain()

  return customIframeUrl
}

export const getTenantCustomImage = (
  image: 'background_image' | 'big_logo' | 'small_logo'
): string | undefined => {
  const tenantName = getTenantName()
  const tenantImages = TENANT_CUSTOM_IMAGES[tenantName]
  return tenantImages?.[image]
}

export const getActiveSession = (): any => {
  let session = localStorage.getItem(constants.session_db_postfix_key)
  if (session) {
    session = JSON.parse(session)
  }
  return session || null
}

export const hasPermission = (permission: string) => {
  const reduxStore = store.getState()
  const {permissions} = reduxStore.userReducer
  for (let i = 0; i < permissions.length; i++) {
    const perm = permissions[i]

    if (
      perm.key === permission ||
      (perm.permissions && perm.permissions.includes(permission)) ||
      (perm.sections && perm.sections.includes(permission))
    )
      return true
  }

  return false
}

export const sanitizeHTML = (str: string, maxLength: number) => {
  if (!str || typeof str !== 'string') {
    return ''
  }

  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = str
  let sanitizedText = tempDiv.textContent || tempDiv.innerText || ''

  if (!sanitizedText.trim()) {
    return ''
  }

  if (sanitizedText.length > maxLength) {
    sanitizedText = sanitizedText.substring(0, maxLength) + '...'
  }

  return sanitizedText
}

export const getTokenLink = () => {
  const reduxStore = store.getState()
  const {accessToken} = reduxStore.userReducer
  const branch = localStorage.getItem('branch')
  const activeSession = getActiveSession()

  return `?rauth_token=${accessToken}&mlayout=false&branchId=${branch ? branch : '0'}${
    activeSession?.db_postfix ? `&db_postfix=${activeSession.db_postfix}` : ''
  }`
}

export const isAdmin = () => {
  const reduxStore = store.getState()
  const {userType} = reduxStore.userReducer
  return userType === 'ADMIN'
}

export const isTeacher = () => {
  const reduxStore = store.getState()
  const {userType} = reduxStore.userReducer
  return userType === 'TEACHER'
}

export const isStaff = () => {
  const reduxStore = store.getState()
  const {userType} = reduxStore.userReducer
  return userType === 'STAFF'
}

export const isStudent = () => {
  const reduxStore = store.getState()
  const {userType} = reduxStore.userReducer
  return userType === 'STUDENT'
}

export const isParent = () => {
  const reduxStore = store.getState()
  const {userType} = reduxStore.userReducer
  return userType === 'PARENT'
}

export const changeFavicon = (url: string) => {
  let link = document.querySelector("link[rel~='icon']") as any
  if (!link) {
    link = document.createElement('link')
    link.rel = 'icon'
    document.getElementsByTagName('head')[0].appendChild(link)
  }
  link.href = url
}

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

export const getVendorStatusString = (status: number) => {
  switch (status) {
    case VENDOR_STATUS.PENDING:
      return 'GENERAL.PENDING'
    case VENDOR_STATUS.ACTIVE:
      return 'GENERAL.ACTIVE'
    case VENDOR_STATUS.BLOCKED:
      return 'GENERAL.BLOCKED'
    default:
      return 'GENERAL.PENDING'
  }
}

export const getLibraryStatusString = (status: number) => {
  switch (status) {
    case LIBRARY_STATUS.ACTIVE:
      return 'GENERAL.ACTIVE'
    case LIBRARY_STATUS.INACTIVE:
      return 'GENERAL.INACTIVE'
    default:
      return 'GENERAL.PENDING'
  }
}

export const getCertificateTypeString = (type: number) => {
  switch (type) {
    case CERTIFICATE_TYPES.STUDENT:
      return 'CERTIFICATE_TYPES.STUDENT'
    case CERTIFICATE_TYPES.HR:
      return 'CERTIFICATE_TYPES.HR'
    case CERTIFICATE_TYPES.CCA_CERTIFICATE:
      return 'CERTIFICATE_TYPES.CCA_CERTIFICATE'
    default:
      return 'N/A'
  }
}

export const getUserTypeName = (type: any) => {
  // eslint-disable-next-line eqeqeq
  const userType = Object.entries(USER_TYPES_NUMERIC).find(([key, value]) => value == type)
  return userType && userType[0] ? userType[0] : null
}

export const getUserInactiveReasonLabel = (reason: number) => {
  switch (reason) {
    case USER_INACTIVE_REASON.INACTIVE:
      return 'USER.INACTIVE_REASON.INACTIVE'
    case USER_INACTIVE_REASON.NEW_ADMISSION:
      return 'USER.INACTIVE_REASON.NEW_ADMISSION'
    case USER_INACTIVE_REASON.ALUMNI:
      return 'USER.INACTIVE_REASON.ALUMNI'
    default:
      return 'N/A'
  }
}

export const getDigitalRequestStatusString = (status: number) => {
  switch (status) {
    case DIGITAL_REQUEST_STATUS.REJECTED:
      return 'DIGITAL_REQUEST_STATUS.REJECTED'
    case DIGITAL_REQUEST_STATUS.PENDING:
      return 'DIGITAL_REQUEST_STATUS.PENDING'
    case DIGITAL_REQUEST_STATUS.APPROVED:
      return 'DIGITAL_REQUEST_STATUS.APPROVED'
    case DIGITAL_REQUEST_STATUS.COMPLETE:
      return 'DIGITAL_REQUEST_STATUS.COMPLETE'
    default:
      return 'DIGITAL_REQUEST_STATUS.PENDING'
  }
}

export const getDigitalRequestStatusBadgeColor = (status: number) => {
  switch (status) {
    case DIGITAL_REQUEST_STATUS.REJECTED:
      return 'badge-light-danger'
    case DIGITAL_REQUEST_STATUS.PENDING:
      return 'badge-light-primary'
    case DIGITAL_REQUEST_STATUS.APPROVED:
      return 'badge-light-success'
    case DIGITAL_REQUEST_STATUS.COMPLETE:
      return 'badge-light-warning'
    default:
      return 'badge-light-primary'
  }
}

export const getDigitalRequestTypeString = (status: number) => {
  switch (status) {
    case DIGITAL_REQUEST_TYPE.CLASS_LEAVE:
      return 'DIGITAL_REQUEST_TYPE.CLASS_LEAVE'
    case DIGITAL_REQUEST_TYPE.ASSESSMENT_LEAVE:
      return 'DIGITAL_REQUEST_TYPE.ASSESSMENT_LEAVE'
    default:
      return 'DIGITAL_REQUEST_TYPE.CLASS_LEAVE'
  }
}

export const getDigitalRequestTypeBadgeColor = (status: number) => {
  switch (status) {
    case DIGITAL_REQUEST_TYPE.CLASS_LEAVE:
      return 'badge-light-info'
    case DIGITAL_REQUEST_TYPE.ASSESSMENT_LEAVE:
      return 'badge-light-primary'
    default:
      return 'badge-light-primary'
  }
}

export const getDigitalRequestObjectTypeBadgeColor = (type: number) => {
  switch (type) {
    case DIGITAL_REQUEST_OBJECT_TYPE.SUBJECT_LEAVE:
      return 'badge-light-primary'

    case DIGITAL_REQUEST_OBJECT_TYPE.EXAM_LEAVE:
      return 'badge-light-success'

    case DIGITAL_REQUEST_OBJECT_TYPE.TEST_LEAVE:
      return 'badge-light-info'

    case DIGITAL_REQUEST_OBJECT_TYPE.ASSIGNMENT_LEAVE:
      return 'badge-light-warning'

    default:
      return 'badge-light-primary'
  }
}

export const getUserTypeBadgeColor = (type: string) => {
  switch (type) {
    case USER_TYPES.ADMIN:
      return 'badge-light-primary'
    case USER_TYPES.TEACHER:
      return 'badge-light-danger'
    case USER_TYPES.STAFF:
      return 'badge-light-warning'
    case USER_TYPES.STUDENT:
      return 'badge-light-info'
    case USER_TYPES.PARENT:
      return 'badge-light-success'

    default:
      return 'badge-light-secondary'
  }
}

export const getUserTypeNumericBadgeColor = (type: number) => {
  switch (type) {
    case USER_TYPES_NUMERIC.ADMIN:
      return 'badge-light-primary'
    case USER_TYPES_NUMERIC.TEACHER:
      return 'badge-light-danger'
    case USER_TYPES_NUMERIC.STAFF:
      return 'badge-light-warning'
    case USER_TYPES_NUMERIC.STUDENT:
      return 'badge-light-info'
    case USER_TYPES_NUMERIC.PARENT:
      return 'badge-light-success'

    default:
      return 'badge-light-secondary'
  }
}

export const getUserTypeString = (type: number) => {
  switch (type) {
    case USER_TYPES_NUMERIC.ADMIN:
      return 'MENU.ADMINISTRATION'
    case USER_TYPES_NUMERIC.TEACHER:
      return 'MENU.TEACHER'
    case USER_TYPES_NUMERIC.STAFF:
      return 'MENU.STAFF'
    case USER_TYPES_NUMERIC.STUDENT:
      return 'MENU.STUDENTS'
    case USER_TYPES_NUMERIC.PARENT:
      return 'MENU.PARENTS'

    default:
      return 'MENU.ADMINISTRATION'
  }
}

export const getUserProfileLink = (type: number, row: any) => {
  switch (type) {
    case USER_TYPES_NUMERIC.ADMIN:
      return `/profile`
    case USER_TYPES_NUMERIC.TEACHER:
      return `/institute/teacher/${row.teacher_id}`
    case USER_TYPES_NUMERIC.STAFF:
      return `/institute/staff/${row.staff_id}`
    case USER_TYPES_NUMERIC.STUDENT:
      return `/institute/student/${row.student_id}`
    case USER_TYPES_NUMERIC.PARENT:
      return `/institute/parent/${row.parent_id}`

    default:
      return `/profile`
  }
}

export const getClassStatusBadgeClass = (status: number): string => {
  switch (status) {
    case CLASSES_STATUS.INACTIVE:
      return 'warning'
    case CLASSES_STATUS.ACTIVE:
      return 'success'
    default:
      return ''
  }
}

export function customTitle(key: any) {
  const mappings = customMappings
  const mainStore = store.getState()
  const selectedLang = getLang().selectedLang
  const customNames = mainStore.mainReducer.custom_titles
  var isTrasnlated = false
  let response = ''
  const mapping = mappings[key as keyof typeof mappings]
  if (mapping && customNames) {
    const customTitleData = customNames[mapping.moduleId]
    if (customTitleData) {
      const selectedLangData = customTitleData[selectedLang]
      if (selectedLangData) {
        let cardinalityWord = ''
        if (mapping.cardinality === 'singular') {
          cardinalityWord = selectedLangData.singular
        } else {
          cardinalityWord = selectedLangData.plural
        }

        let sentence = mapping.sentences[selectedLang as keyof typeof mapping.sentences]
        if (cardinalityWord) {
          response = sentence.replace(/\[.*\]/g, cardinalityWord)
          isTrasnlated = true
        }
      } else {
        response = key
      }
    } else {
      response = key
    }
  } else {
    response = key
  }
  let langDictionary = null
  if (!isTrasnlated) {
    langDictionary = enDictonary
    if (selectedLang === 'ur') {
      langDictionary = urDictonary
    } else if (selectedLang === 'ar') {
      langDictionary = arDictonary
    }

    if (langDictionary && langDictionary[key as keyof typeof langDictionary]) {
      response = langDictionary[key as keyof typeof langDictionary]
    }
  }
  return response
}

export const parseDocumentObject = (document: any) => {
  return document.url
}

export const getClassesList = (): Option[] => {
  const reduxStore = store.getState()
  const {classes} = reduxStore?.mainReducer
  const filteredClasses =
    classes &&
    classes
      .filter((item: any) => item.status === 1)
      .map((item: any) => ({
        label: `${item?.name} ${item.branch_name}`,
        value: item?.id,
      }))

  return filteredClasses || []
}

export const getActiveClasses = (classes: any[]): Option[] => {
  return (
    classes
      ?.filter((item) => item.status === 1)
      .map((item) => ({
        label: `${item.name} ${item.branch_name}`,
        value: item.id,
      })) || []
  )
}

type DownloadFileProps = {
  path: string
  fileName?: string
  method?: 'GET' | 'POST'
  body?: any
  onSuccess?: (res: any) => void
  onFailure?: (err: any) => void
}
export const downloadFile = ({
  path,
  fileName,
  method = 'GET',
  body,
  onSuccess,
  onFailure,
}: DownloadFileProps) => {
  const userReducer = store.getState().userReducer

  const axiosConfig: any = {
    method: method,
    url: `${base_url}${path}`,
    headers: {
      Authorization: `Bearer ${userReducer.accessToken}`,
      domain: getTenantDomain(),
      platform: 'web',
      platformkey: SECRET_KEY['web'],
      version: '1.0.0',
    },
    responseType: 'blob',
  }

  if (method === 'POST') {
    axiosConfig.data = body
  }

  axios(axiosConfig)
    .then((response) => {
      const contentDisposition = response.headers['content-disposition']
      const responseFileName = contentDisposition
        ?.split(';')[1]
        .split('=')[1]
        .trim()
        .replaceAll('"', '')

      const blob = new Blob([response.data], {type: response.headers['content-type']})
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName || responseFileName
      document.body.appendChild(a)
      a.click()
      a.remove()

      if (typeof onSuccess === 'function') {
        onSuccess(response)
      }
    })
    .catch((err) => {
      if (typeof onFailure === 'function') {
        onFailure(err)
      }
    })
}

export const downloadFileFromUrl = ({path, fileName, onSuccess, onFailure}: DownloadFileProps) => {
  const userReducer = store.getState().userReducer

  axios
    .get(`${base_url}${path}`, {
      headers: {
        Authorization: `Bearer ${userReducer.accessToken}`,
        domain: getTenantDomain(),
        platform: 'web',
        platformkey: SECRET_KEY['web'],
        version: '1.0.0',
      },
      responseType: 'json',
    })
    .then((response) => {
      let element = document.createElement('a')
      element.style.display = 'none'
      element.setAttribute('href', response.data.url)
      element.setAttribute('download', response.data.name)
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
      // console.log(response)
      //const contentDisposition = response.headers['content-disposition']
      // const responseFileName = contentDisposition
      //   ?.split(';')[1]
      //   .split('=')[1]
      //   .trim()
      //   .replaceAll('"', '')

      // const blob = new Blob([response.data], {type: response.headers['content-type']})
      // console.log(responseFileName)
      // const url = window.URL.createObjectURL(blob)
      // const a = document.createElement('a')
      // a.href = url
      // a.download = fileName || responseFileName
      // document.body.appendChild(a)
      // a.click()
      // a.remove()

      if (typeof onSuccess === 'function') {
        onSuccess(response)
      }
    })
    .catch((err) => {
      if (typeof onFailure === 'function') {
        onFailure(err)
      }
    })
}

export const CustomDownloadFile = (
  content: string,
  filename: string,
  mimeType: string = 'text/plain'
) => {
  const blob = new Blob([content], {type: mimeType})
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)
  link.setAttribute('href', url)
  link.setAttribute('download', filename)
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getGradeStatusString = (status: number) => {
  switch (status) {
    case GRADE_STATUS.ACTIVE:
      return 'GENERAL.ACTIVE'
    case GRADE_STATUS.INACTIVE:
      return 'GENERAL.INACTIVE'
    default:
      return 'GENERAL.PENDING'
  }
}

export const getClassStatusString = (status: number) => {
  switch (status) {
    case CLASSES_STATUS.ACTIVE:
      return 'GENERAL.ACTIVE'
    case CLASSES_STATUS.INACTIVE:
      return 'GENERAL.INACTIVE'
    default:
      return 'GENERAL.PENDING'
  }
}

export const getAllowedGradesList = (): Option[] => {
  const reduxStore = store.getState()
  const {classes} = reduxStore?.mainReducer

  const uniqueGradeIds = new Set()

  const filteredGrades =
    classes &&
    classes
      .filter((item: any) => {
        if (item.status === 1 && !uniqueGradeIds.has(item.gradeId)) {
          uniqueGradeIds.add(item.gradeId)
          return true
        }
        return false
      })
      .map((item: any) => ({
        label: `${item?.grade_name} (${item.level_name}) (${item.branch_name})`,
        value: item?.gradeId,
      }))

  return filteredGrades || []
}

export const getBranchNameById = (_branchId: string | number | undefined = 1): string => {
  const reduxStore = store.getState()
  const {branches: branchList} = reduxStore?.mainReducer

  const branchId = +_branchId
  const selectedBranch = branchList.find((item: any) => item.id === branchId)

  return selectedBranch?.name
}

export const getAttendanceStatusBadgeColor = (status: number) => {
  switch (status) {
    case ATTENDANCE_STATUS.STATUS_ABSENT:
      return 'badge-light-danger'
    case ATTENDANCE_STATUS.STATUS_LEAVE:
      return 'badge-light-info'
    case ATTENDANCE_STATUS.STATUS_LATE_PRESENT:
      return 'badge-light-warning'
    case ATTENDANCE_STATUS.STATUS_PRESENT:
      return 'badge-light-success'
    default:
      return 'badge-light-warning'
  }
}

export const getAttendanceCheckoutStatusBadgeColor = (status: number) => {
  switch (status) {
    case ATTENDANCE_STATUS.STATUS_ON_TIME_CHECKOUT:
      return 'badge-light-success'
    case ATTENDANCE_STATUS.STATUS_EARLY_CHECKOUT:
      return 'badge-light-danger'
    default:
      return 'badge-light-warning'
  }
}

export const getAttendancePercentageBadgeColor = (percentage: number, cutOffPercentage: number) => {
  if (percentage < cutOffPercentage) {
    return 'badge-light-danger'
  } else {
    return 'badge-light-success'
  }
}

export const getAttendanceStatusString = (status: number) => {
  switch (status) {
    case ATTENDANCE_STATUS.STATUS_ABSENT:
      return 'ATTENDANCE.ATTENDANCE_GRID.STATUS_ABSENT'
    case ATTENDANCE_STATUS.STATUS_LEAVE:
      return 'ATTENDANCE.ATTENDANCE_GRID.STATUS_LEAVE'
    case ATTENDANCE_STATUS.STATUS_LATE_PRESENT:
      return 'ATTENDANCE.ATTENDANCE_GRID.STATUS_LATE'
    case ATTENDANCE_STATUS.STATUS_PRESENT:
      return 'ATTENDANCE.ATTENDANCE_GRID.STATUS_PRESENT'
    default:
      break
  }
}

export const getAttendanceCheckoutStatusString = (status: number) => {
  switch (status) {
    case ATTENDANCE_STATUS.STATUS_EARLY_CHECKOUT:
      return 'ATTENDANCE.ATTENDANCE_GRID.CHECKOUT_EARLY_CHECKOUT'
    case ATTENDANCE_STATUS.STATUS_ON_TIME_CHECKOUT:
      return 'ATTENDANCE.ATTENDANCE_GRID.CHECKOUT_ON_TIME_CHECKOUT'
    default:
      return 'ATTENDANCE.ATTENDANCE_GRID.UNKNOWN_STATUS'
  }
}

export const getLoginStatusBadgeColor = (status: string | number) =>
  status === 1 ? 'badge-success' : 'badge-danger'

export const getLoginStatusString = (status: number) =>
  status === 1 ? 'GENERAL.YES' : 'GENERAL.NO'

export const getNewsStatusBadgeColor = (status: string | number) =>
  status === 1 ? 'badge-success' : 'badge-warning'

export const getNewsPublishedString = (status: number) =>
  status === 1 ? 'NEWS.PUBLISHED' : 'NEWS.SCHEDULED'

export const isClasswiseInstance = () => {
  const reduxStore = store.getState()
  const {generalSettings} = reduxStore.mainReducer
  return generalSettings.subject_settings_permission === CLASS_WISE_INSTANCE
}

export const isSubjectwiseInstance = () => {
  const reduxStore = store.getState()
  const {generalSettings} = reduxStore.mainReducer
  return generalSettings.subject_settings_permission === SUBJECT_WISE_INSTANCE
}

export const getCurrentAndPreviousFourYears = () => {
  const currentYear = moment().year()
  const years = []
  for (let i = 0; i < 5; i++) {
    const year = currentYear - i
    years.push({label: year, value: year})
  }
  return years
}

export const getAllMonths = () => {
  const months = []
  for (let i = 0; i < 12; i++) {
    months.push({label: moment().month(i).format('MMMM'), value: i})
  }
  return months
}

export const isDevEnv = (): boolean => {
  return process.env.REACT_APP_ENV !== 'prod'
}

export const getMonthsForPreviousCurrentAndNextYear = () => {
  const currentYear = moment().year()
  const years = [currentYear - 1, currentYear, currentYear + 1]

  const monthsForYears = years.flatMap((year) =>
    moment.months().map((month, index) => ({
      label: moment().year(year).month(index).format('MMM YYYY'),
      value: moment().year(year).month(index).format('YYYY-MM'),
    }))
  )
  return monthsForYears
}

export const getDirtyValues = (initialValues: any, currentValues: any) => {
  return _.pickBy(currentValues, (value, key) => !_.isEqual(value, initialValues[key]))
}

export const getGenderName = (gender: number, intl: IntlShape): string => {
  switch (gender) {
    case GENDER.MALE:
      return intl.$t({id: 'GENDER.MALE'})
    case GENDER.FEMALE:
      return intl.$t({id: 'GENDER.FEMALE'})
    default:
      return intl.$t({id: 'GENDER.MALE'})
  }
}

export const convertTo12HourFormat = (time24: any) => {
  const [hours, minutes] = time24.split(':')
  let hours12 = parseInt(hours, 10)
  const period = hours12 >= 12 ? 'PM' : 'AM'
  hours12 = hours12 % 12 || 12
  return `${hours12}:${minutes} ${period}`
}

export const formattedTime = (date: any) => {
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  return `${hours}:${minutes}:00`
}

export const customFormatDate = (date: any) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const getSubjectStatusString = (status: number) => {
  switch (status) {
    case SUBJECTS_STATUS.ACTIVE:
      return 'GENERAL.ACTIVE'
    case SUBJECTS_STATUS.INACTIVE:
      return 'GENERAL.INACTIVE'
    default:
      return 'GENERAL.PENDING'
  }
}

export const getGradesList = (grades: any[]): Option[] => {
  const branch = localStorage.getItem('branch')
  let filteredGrades: any
  if (branch !== null) {
    filteredGrades =
      grades &&
      grades
        .filter((item: any) => item.status === 1 && item.branchId == branch)
        .map((item: any) => ({
          label: `${item?.name} (${item?.levelName})`,
          value: item?.id,
          select: true,
        }))
  } else {
    filteredGrades =
      grades &&
      grades
        .filter((item: any) => item.status === 1)
        .map((item: any) => ({
          label: `${item?.name} (${item?.branchName})`,
          value: item?.id,
          select: true,
        }))
  }

  return filteredGrades || []
}

export const getSubjectClassesList = (gradeId: string | null = null): Option[] => {
  const reduxStore = store.getState()
  const {classes} = reduxStore?.mainReducer

  const filteredClasses = classes
    // eslint-disable-next-line eqeqeq
    ?.filter((item: any) => item.status === 1 && item.gradeId == gradeId)
    .map((item: any) => ({
      label: `${item?.name} ${item.branch_name}`,
      value: item?.id,
    }))
  return filteredClasses || []
}

export const calculateAge = (dob: string | Date) => {
  const birthDate = new Date(dob)
  const today = new Date()

  let years = today.getFullYear() - birthDate.getFullYear()
  let months = today.getMonth() - birthDate.getMonth()

  if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
    years--
    months = (months + 12) % 12
  }

  return `${years} Years ${months} Months`
}

export const getClassSubejctStatusString = (status: number) => {
  switch (status) {
    case CLASSES_SUBJECT_STATUS.YES:
      return 'GENERAL.YES'
    case CLASSES_SUBJECT_STATUS.NO:
      return 'GENERAL.NO'
    default:
      return 'GENERAL.PENDING'
  }
}

/**
 * Get an array of dates between two specified dates.
 * @param {string} startDate - The starting date in 'YYYY-MM-DD' format.
 * @param {string} endDate - The ending date in 'YYYY-MM-DD' format.
 * @returns {string[]} An array of date strings in 'YYYY-MM-DD' format.
 */
export const getDatesBetween = (startDate: string, endDate: string) => {
  const start = moment(startDate)
  const end = moment(endDate)
  const dates = []
  while (start.isBefore(end) || start.isSame(end)) {
    dates.push(start.format('YYYY-MM-DD'))
    start.add(1, 'days')
  }
  return dates
}

export const getBranchSubjectClassesList = (branchId: string | null = null): Option[] => {
  const reduxStore = store.getState()
  const {classes} = reduxStore?.mainReducer

  const filteredClasses = classes
    ?.filter((item: any) => item.status === 1 && item.branch_id === branchId)
    .map((item: any) => ({
      label: `${item?.name} ${item.branch_name}`,
      value: item?.id,
    }))
  return filteredClasses || []
}

export const getRegistrationStatus = (status: number, intl: IntlShape) => {
  switch (status) {
    case REGISTRATION_STATUS.REGISTERED_AND_ADMITTED:
      return intl.$t({id: 'STUDENT.REGISTERED_AND_ADMITTED'})
    case REGISTRATION_STATUS.ONLY_REGISTERED:
      return intl.$t({id: 'STUDENT.ONLY_REGISTERED'})
    default:
      return intl.$t({id: 'STUDENT.REGISTERED_AND_ADMITTED'})
  }
}

export const getViewStatus = (status: number, intl: IntlShape) => {
  switch (status) {
    case GRADE_STATUS.INACTIVE:
      return intl.$t({id: 'GENERAL.YES'})
    case GRADE_STATUS.ACTIVE:
      return intl.$t({id: 'GENERAL.NO'})
    default:
      return intl.$t({id: 'CLASS.NOT_SET'})
  }
}

export const getMaritalStatusLabel = (status: number, intl: IntlShape) => {
  switch (status) {
    case TEACHER_MARITAL_STATUS.MARRIED:
      return intl.formatMessage({id: 'MARITAL.MARRIED'})
    case TEACHER_MARITAL_STATUS.SEPARATED:
      return intl.formatMessage({id: 'MARITAL.SEPARATED'})
    case TEACHER_MARITAL_STATUS.DIVORCED:
      return intl.formatMessage({id: 'MARITAL.DIVORCED'})
    case TEACHER_MARITAL_STATUS.WIDOW:
      return intl.formatMessage({id: 'MARITAL.WIDOW'})
    case TEACHER_MARITAL_STATUS.SINGLE:
      return intl.formatMessage({id: 'MARITAL.SINGLE'})
    default:
      return intl.formatMessage({id: 'CLASS.NOT_SET'})
  }
}

export const isLgs = () => {
  return ['lgs', 'lgsdev2'].includes(getTenantName())
}

export const convertS3Url = (originalUrl: string) => {
  if (!originalUrl.includes('ilmversity-uploads-bkp')) {
    const newUrl = originalUrl.replace('ilmversity-uploads', 'ilmversity-uploads-bkp')
    return newUrl
  }
  return originalUrl
}

export const getStudentListGrades = (): Option[] => {
  const reduxStore = store.getState()
  const {grades} = reduxStore?.mainReducer
  const branch = localStorage.getItem('branch')
  let filteredGrades = []

  if (branch === null) {
    filteredGrades =
      grades &&
      grades
        .filter((item: any) => item.status === 1)
        .map((item: any) => ({
          label: `${item?.name} ${item?.levelName}`,
          value: item?.id,
          select: true,
        }))
  } else {
    filteredGrades =
      grades &&
      grades
        .filter((item: any) => item.status === 1 && item.branchId == branch)
        .map((item: any) => ({
          label: `${item?.name} ${item?.levelName}`,
          value: item?.id,
          select: true,
        }))
  }

  return filteredGrades || []
}
