import {put, select} from 'redux-saga/effects'
import Constants, {base_url, SECRET_KEY, USER_TYPES} from 'common/constants'
import {logout} from '../Actions/SignIn/logout'
import {GET_USER_SETTINGS} from '../Actions/User/ActionTypes/ApiActionTypes'
import {LOGOUT_USER} from '../Actions/SignIn/ActionTypes/ApiActionTypes'
import {getActiveSession, getTenantDomain, hasPermission, isAdmin} from 'common/HelperFunctions'
import {setTheme} from 'common/themes/ThemeHelpers'
import {REDUX_SET_BRANCHES} from '../Actions/General/ActionTypes/ReduxActionTypes'
import localforage from 'localforage'

const DEBUG_LOG_API = false
const DEBUG_LOG_API_REQUEST = false
const DEBUG_LOG_API_RAW_RESPONSE = false
const DEBUG_LOG_API_JSON_RESPONSE = false

const debug_log = (should_log, text, variable) => {
  if (should_log) {
    console.log('******** ' + text + ' **************')
    console.log(variable)
  }
}

const append_ids = (userInfo, body, useFeeBaseURL) => {
  let parentId
  let childId
  // for parent user type
  if (userInfo.userType === USER_TYPES.PARENT) {
    parentId = userInfo.user?.id
    childId = userInfo.selectedChild?.id
  }
  //for student user type
  else if (userInfo.userType === USER_TYPES.STUDENT) {
    parentId = userInfo.user?.parentOrGuardian[0].id
    childId = userInfo.user?.id
  }

  if (useFeeBaseURL) {
    body.append('username', Constants.username_fee_api)
    body.append('password', Constants.password_fee_api)
    body.append('studentUserId', childId)
    body.append('parentUserId', parentId)
  } else if (body instanceof FormData) {
    if (childId) body.append('childId', childId)
    if (parentId) body.append('parentId', parentId)
  } else {
    if (!body) body = {}
    if (childId) body.childId = childId
    if (parentId) body.parentId = parentId
  }
  return body
}

function* fetchData(action) {
  const all_state = yield select()
  const userInfo = all_state.userReducer
  const {type} = action

  try {
    let complete_url = action.payload.apiUrl + action.payload.getString
    const {useBaseURL, useFeeBaseURL} = action.payload

    if (useBaseURL) {
      complete_url = base_url + action.payload.apiUrl + action.payload.getString
    } else if (useFeeBaseURL) {
      complete_url =
        `https://${userInfo.tenant}.ilmversity.net/` +
        action.payload.apiUrl +
        action.payload.getString
    }

    debug_log(DEBUG_LOG_API, action.payload.requestType + ' API URL', complete_url)

    let {body} = action.payload
    let request = {
      headers: {
        Accept: 'application/json',
        domain: getTenantDomain(),
        platform: 'web',
        platformKey: SECRET_KEY['web'],
        version: '1.0.0',
        ...(action.payload.header2 && action.payload.header2),
      },
      method: action.payload.requestType,
    }

    const activeSession = getActiveSession()

    if (activeSession?.db_postfix) {
      request.headers['session-postfix'] = activeSession.db_postfix
    }

    if (!(body instanceof FormData)) {
      request.headers['Content-Type'] = action.payload.header
    }

    const branchId = localStorage.getItem('branch')

    if (branchId) {
      request.headers['branch-id'] = branchId
    }

    if (userInfo.accessToken && !action.payload.noAccessToken) {
      request.headers.Authorization = 'Bearer ' + userInfo.accessToken
    }

    if (action.payload.requestType !== 'GET') {
      body = append_ids(userInfo, body, useFeeBaseURL)
      request = {
        ...request,
        body:
          ['application/x-www-form-urlencoded', 'multipart/form-data'].includes(
            action.payload.header
          ) || useFeeBaseURL
            ? body
            : JSON.stringify(body),
      }
    }

    debug_log(DEBUG_LOG_API_REQUEST, 'API REQUEST', request)

    if (base_url !== '') {
      const data = yield fetch(complete_url, request)

      debug_log(DEBUG_LOG_API_RAW_RESPONSE, 'API RAW RESPONSE', data)

      let response
      if (action.payload.htmlResponse) {
        response = yield data.text() //todo check on diff fee api object
      } else {
        response = yield data.json()
      }
      debug_log(DEBUG_LOG_API_JSON_RESPONSE, 'API JSON RESPONSE', response)
      if (
        response.status === 'true' ||
        response.success === 'true' ||
        useFeeBaseURL //todo fee api object
      ) {
        if (!!action.payload.reduxActionType) {
          const payload = action.payload.response ? action.payload.response : response

          // * userBranches are set in the payload in fetchData saga helper as we can not access userReducer here which is need for filtering branches
          if (action.payload.reduxActionType === REDUX_SET_BRANCHES) {
            payload.userBranches = response.result.filter(
              (branch) => isAdmin() || hasPermission(`branch_permission_${branch.id}`)
            )
          }

          yield put({
            type: action.payload.reduxActionType,
            payload,
            requestParams: body,
          })
        }
        if (action.payload.onSuccess) {
          action.payload.onSuccess(response)
        }
      } else {
        if (
          all_state.userReducer.accessToken &&
          (response.statusCode === 401 || type === GET_USER_SETTINGS)
        ) {
          localStorage.clear()
          localforage.clear()
          setTheme('light')
          yield put(logout())
          window.location.href = '/logout'
        }

        if (action.payload.onFailure) {
          action.payload.onFailure(response)
        }
      }
    }
  } catch (e) {
    // TODO: update
    // handleErrors(e)

    if (type === GET_USER_SETTINGS) {
      yield put(logout())
    }

    if (type === LOGOUT_USER) {
      localStorage.clear()
      setTheme('light')
    }
  }
}

export default fetchData
